import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';

import AlbumForm from '../forms/AlbumForm';

import { routes } from '../navigation';
import { classNames } from '../styling';
import api from '../api';

import { mockAlbum } from '../mock-data';


/**
 * Create/Edit Album
 * @see #40 no layouts provided
 */
const Album = function() {
    const { album_id } = useParams();

    const [album, setAlbum] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const handleAlbumFormSubmit = function(data) {
        setLoading(true);
        api.mock(data)
            .then(data => {
                setLoading(false);
                return setAlbum(data); // TODO redirect to AlbumPages
            })
            .catch(error => {
                setLoading(true);
                return setError(error);
            });
    };

    useEffect(() => {
        if (album_id === 'new') {
            setLoading(false);
            return;
        }

        setLoading(true);
        api.mock(mockAlbum)
            .then(res => {
                setAlbum(res);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [album_id]);

    const width='w-full sm:max-w-2xl'; // 672px

    let title = (album) ? album.name : '';
    title = (!title && album_id === 'new') ? 'New' : '';
    title = (title) ? `Album: ${title}`: 'Album';

    return (
        <Page.Simple>
            <PageTitle route={ {
                name: title,
                icon: routes.albums.icon
            } } />
            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ loading }>
                <div className={ classNames(width, 'mx-auto') }>
                    <AlbumForm
                        album={ album }
                        handleSubmit={ handleAlbumFormSubmit }
                    />
                </div>
            </IfLoaded>
        </Page.Simple>
    );
};


export default Album;
