import { classNames } from '../styling';


const Preloader = function({ loading, className, message, bgColor='bg-reminu--light', fgColor='bg-reminu--dark' }) {
    if (!loading) {
        return (null);
    }

    const bgClass = classNames(bgColor, 'flex items-center justify-center px-8 py-4 rounded-lg');
    const fgClass = classNames(fgColor, 'w-3 h-3 bg-gray-500 rounded-full');

    return (
        <div className={ classNames(className, 'my-4') }>
            <div className={ bgClass }>
                <figure className="flex space-x-2 animate-pulse">
                    <div className={ fgClass }></div>
                    <div className={ fgClass }></div>
                    <div className={ fgClass }></div>
                    <span className="sr-only">Loading...</span>
                </figure> { message && <span className="ml-4">{ message }</span> }
            </div>
        </div>
    );
};


export default Preloader;
