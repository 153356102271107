import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'; // pro: faCirclePlus

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';

import PageForm from '../forms/PageForm';
import ParagraphForm from '../forms/ParagraphForm';

import { routes } from '../navigation';
import { Styles, classNames } from '../styling';
import api from '../api';

import { mockAlbumPage, mockAlbumPageParagraphs } from '../mock-data';


const LOADING_NONE = 0;
const LOADING_LEFT = 1;
const LOADING_RIGHT = 2;
const LOADING_ALL = 4;

const route = {
    name: 'New page',
    path: routes.albumPageEdit.path(0, 'new'),
    icon: faSquarePlus,
};


const AlbumPage = function() {
    const [page, setPage] = useState(null);
    const [paragraphs, setParagraphs] = useState([]);
    const [count, setCount] = useState(0); // using this only for triggering re-renders

    const [loading, setLoading] = useState(LOADING_NONE);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(LOADING_ALL);
        Promise.all([
            api.mock(mockAlbumPage),
            api.mock(mockAlbumPageParagraphs)
        ])
        .then((values) => {
            setPage(values[0]);
            setParagraphs(values[1]);

            setCount(values[1].length);
            setLoading(LOADING_NONE);
        })
        .catch(error => {
            setLoading(LOADING_NONE);
            setError(error);
        });
    }, []);

    const handlePageFormSubmit = function(data) {
        setLoading(LOADING_LEFT);
        api.mock(data)
            .then(data => {
                setLoading(LOADING_NONE);
                return setPage(data)
            })
            .catch(error => {
                setLoading(LOADING_NONE);
                return setError(error);
            });
    };

    const handleParagraphFormSubmit = function(data) {
        setLoading(LOADING_RIGHT);
        api.mock(data)
            .then(data => {
                setLoading(LOADING_NONE);
                return setPage(data)
            })
            .catch(error => {
                setLoading(LOADING_NONE);
                return setError(error);
            });
    };

    const addNewParagraph = function() {
        paragraphs.push({
            headline: '',
            content: '',
            pullquote: '',
        });
        setParagraphs(paragraphs);
        setCount(paragraphs.length);
    };

    return (
        <Page.Plain className="h-full">
            { /* <PageTitle route={ routes.profile } /> */ }

            <Preloader loading={ loading > LOADING_NONE } message="fetching contents" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ (loading & LOADING_ALL) }>
                <div className="h-full md:grid md:grid-cols-3 md:gap-4 content-stretch">
                    <section className={ classNames(Styles.pagePadding) }>
                        <PageTitle route={ route } />
                        <IfLoaded loading={ (loading & LOADING_LEFT) }>
                            <PageForm
                                page={ page }
                                handleSubmit={ handlePageFormSubmit }
                            />
                            <div className="md:hidden mt-6 text-center">
                                <Link className={ Styles.btnPrimary } to={ routes.albumPage.path(0, 0) }>View layout</Link>
                            </div>
                        </IfLoaded>
                    </section>

                    <section className={ classNames(Styles.pagePadding, 'hidden md:block', 'p-8 col-span-2 bg-reminu--grey-6') }>
                        <h3 className="block font-bold mb-4">Preview</h3>
                        <IfLoaded loading={ (loading & LOADING_RIGHT) }>
                            <div className="p-8 bg-reminu--grey-5 rounded" >
                                <h2 className={ Styles.h2xl }>{ (page) ? page.title : '' }</h2>
                                <div className="mb-4">{ (page) ? page.subtitle  : '' }</div>

                                <div className="grid grid-flow-row-dense gap-4 grid-cols-2">
                                    {
                                        paragraphs.map(
                                            (item, index) => (
                                                <div key={ index }>
                                                    <ParagraphForm
                                                        key={ index }
                                                        className="w-full p-2 bg-reminu--grey-5 rounded"
                                                        paragaph={ item }
                                                        handleSubmit={ handleParagraphFormSubmit }
                                                    />
                                                </div>
                                        ))
                                    }
                                </div>

                                <div className="w-full p-2">
                                    <button
                                        type="button"
                                        className={ Styles.btnSecondary }
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                addNewParagraph();
                                            }
                                        }
                                    >Add paragraph ({ count })</button>
                                </div>
                            </div>
                        </IfLoaded>
                    </section>
                </div>
            </IfLoaded>
        </Page.Plain>
    );
};


export default AlbumPage;
