import { Link } from "react-router-dom";

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import { routes } from '../navigation';

import { Styles } from '../styling';


const NotFound  = function() {
    return (
        <Page.Simple>
            <PageTitle route={ routes.notfound } />
            <div>
                <p className="font-bold mb-4">This page doesn't exist!</p>
                <p>
                    <Link to="/" className={ Styles.btnPrimary }>Home</Link>
                </p>
            </div>
        </Page.Simple>
    );
};


export default NotFound;
