import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames, Styles } from '../styling';


/**
 * @see navigation.js
 */
const PageTitle = function ({ route, className }) {

    const { name, icon } = route;

    return (
        <h1 className={ classNames(className, Styles.h1 ) }>
            { icon ? <FontAwesomeIcon icon={ icon } aria-hidden="true" className="pr-2" /> : '' } { name || '<Unknown Page>' }
        </h1>
    );
};


export default PageTitle;
