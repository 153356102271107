import { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'; // pro: faCirclePlus

import MediaUpload from './MediaUpload';

import { classNames, Styles } from '../styling';


const allowedMedia = ['image'];


/**
 * Album form
 */
const AlbumForm = function({ album, handleSubmit, className }) {

    const taHeight = 'h-28'; // 112px

    const nameRef = useRef();
    const descriptionRef = useRef();

    const onSubmit = function(e) {
        e.preventDefault();

        album = album || {};
        album.name = nameRef.current.value;
        album.description = descriptionRef.current.value;

        handleSubmit(album);
    };

    return(
        <form className={ classNames(className, 'w-full') } onSubmit={ onSubmit }>

            <fieldset className="mb-4">
                <label htmlFor="name">Name</label>

                <input
                    name="name"
                    className={ classNames('mt-2 w-full', Styles.textInput) }
                    defaultValue={ (album) ? album.name : '' }
                    ref={ nameRef }
                    placeholder="Album name..."
                />
            </fieldset>

            <fieldset className="mb-4">
                <label htmlFor="description">Description</label>

                <textarea
                    name="description"
                    className={ classNames('mt-2 w-full', taHeight, Styles.textInput) }
                    defaultValue={ (album) ? album.description : '' }
                    ref={ descriptionRef }
                    placeholder="Album summary..."
                />
            </fieldset>

            <fieldset className="mb-4">
                <div className="w-full">Album image...</div>
                <MediaUpload
                    appearance="simple"
                    media={ null }
                    allowedMedia={ allowedMedia }
                    className={ classNames(Styles.btnLight, 'w-full h-16 p-8 text-xl') }
                />
            </fieldset>

            <fieldset className="mb-4">
                <button
                    type="submit"
                    className={ classNames('font-bold', Styles.btnRounded) }
                >
                    <FontAwesomeIcon icon={ faCircleRight } /> Save
                </button>
            </fieldset>

        </form>
    );
};


export default AlbumForm;
