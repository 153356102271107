import { useContext } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppProvider, AppContext } from './context/AppContext';

import ProtectedRoute  from './router/ProtectedRoute';

import Appbar from './components/Appbar';
import Footer from './components/Footer';
import Alert from './components/Alert';

import Dev from './components/Dev';

import Home from './pages/Home';
import Albums from './pages/Albums';
import GuidedPlans from './pages/GuidedPlans';

import Profile from './pages/Profile';
import Album from './pages/Album';
import GuidedPlan from './pages/GuidedPlan';
import AlbumPage from './pages/AlbumPage';
import AlbumPageLayout from './pages/AlbumPageLayout';
import AlbumPages from './pages/AlbumPages';

import Brand from './pages/Brand';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import Todo from './pages/Todo';

import { classNames, Styles } from './styling';
import { get_route, ensure_route } from './navigation';


const Layout = function({ user, error, setError }) {
    const authContext = useContext(AuthContext);
    const appContext = useContext(AppContext);

    const main = (appContext.dark) ? Styles.dark : Styles.light;

    return (
        <div className="flex flex-col h-screen min-h-screen justify-between font-inter">
            <Alert.Error message={ authContext.error } />
            <header className={ classNames(Styles.darker, Styles.pagePaddingX) }>
                <Appbar />
            </header>
            <main className={ classNames(main, 'flex-grow mb-auto') }>
                { <Outlet /> }
            </main>
            <Footer className={ Styles.footerPadding } />
            <Dev top className="px-6 py-1 border-t-white bg-amber-500/50">
                <Dev.Responsive />
                <Dev.NavigateTo />
            </Dev>
        </div>
    );
};

const App = function() {

    return (
        <AuthProvider>
            <AppProvider>
                <Routes>
                    <Route path="/" element={ <Layout /> }>

                        <Route element={ <ProtectedRoute /> }>
                            <Route index element={ <Navigate to={ ensure_route('home') } replace /> } />
                            <Route path={ ensure_route('home') } element={ <Home /> } />
                            <Route path={ ensure_route("albums") } exact element={ <Albums /> } />

                            <Route path={ ensure_route("albums/:album_id") } exact element={ <AlbumPages /> } />
                            <Route path={ ensure_route("albums/:album_id/edit") } exact element={ <Album /> } />

                            { /* TODO clarify if extra routes/views required */ }
                            <Route path={ ensure_route("albums/:album_id/chapters/:chapter_id/edit") } exact element={ <Todo route={ get_route('albumChapterEdit') } /> } />
                            <Route path={ ensure_route("albums/:album_id/chapters/:chapter_id") } exact element={ <Todo route={ get_route('albumChapter') } /> } />

                            <Route path={ ensure_route("albums/:album_id/pages/:page_id") } exact element={ <AlbumPageLayout /> } />
                            <Route path={ ensure_route("albums/:album_id/pages/:page_id/edit") } element={ <AlbumPage /> } />

                            <Route path={ ensure_route("guided-plans") } exact element={ <GuidedPlans /> } />
                            <Route path={ ensure_route("guided-plans/:plan_id") } element={ <GuidedPlan /> } />

                            <Route path={ ensure_route("profile") } element={ <Profile /> } />
                            <Route path={ ensure_route("notifications") } exact element={ <Profile /> } />
                            <Route path={ ensure_route("notifications/:notification_id") } exact element={ <Todo route={ get_route('notification') } /> } />
                        </Route>

                        <Route path={ ensure_route("login") } element={ <Login /> } />
                        <Route path={ ensure_route("logout") } element={ <Logout /> } />
                        <Route path={ ensure_route("brand") } element={ <Brand /> } />
                        <Route path="*" element={ <NotFound /> } />

                    </Route>
                </Routes>
            </AppProvider>
        </AuthProvider>
    );
}


export default App;
