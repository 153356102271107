import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import api from '../api';
import { userStub } from '../mock-data';


const AuthContext = createContext({ user: null });

const transformResponse = function(data) {
    data = data || {};

    const user = Object.assign({}, data, userStub);
    user.fullName = [user.first_name, user.last_name].join(' ');
    return user;
};

const AuthProvider = function({ children }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    const init = function() {
        setLoading(false);
        setError(null);
        return Promise.resolve(true);
    }

    const handleError = function(error) { console.log('--',error.status);
        setLoading(false);
        setError(error);
        // setUser(null); ! commented out and left here deliberately
    }

    const handleUserData = function(data) {
        const user = (data) ? transformResponse(data) : null;
        setLoading(false);
        setError(null);
        setUser(user);
    }

    const test = function() {
        init();
        return api.profile(true)
            .then(res => handleUserData(res))
            .catch(error => {
                setLoading(false);

                // get http status from ApiError
                let status = undefined;
                if (error instanceof Error) {
                    status = error.status;
                }
                status = parseInt(status, 10);

                switch (status) {
                    case 400:
                    case 401:
                    case 403:
                        navigate('/login');
                    break;

                    default:
                        handleError(error);
                }
            });
    };

    const login = function(email, password) {
        init();
        return api.login({ email, password })
            .then(res => handleUserData(res))
            .catch(error => handleError(error));
    };

    const logout = function() {
        init();
        return api.logout()
            .then(res => handleUserData(null))
            .catch(error => handleError(error));
    };

    // local helpers

    const isLoggedIn = function() {
        return (user && user.email);
    };

    const getUser = function() {
        return user || null;
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        test();
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <AuthContext.Provider value={{
            user,
            loading,
            error,

            // api methods
            test,
            login,
            logout,

            isLoggedIn,
            getUser,
        }}>
            { children }
        </AuthContext.Provider>
    );
};


export { AuthContext, AuthProvider };
