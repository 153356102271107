import { classNames, Styles } from '../styling';


const Page = {}

Page.Plain = function PagePlain({ className, children }) {
    return (
        <div className= { classNames(className, 'page-plain') }>{ children }</div>
    );
};

Page.Simple = function PageSimple({ className, children }) {
    return (
        <div className= { classNames(className, 'page-simple', Styles.pagePadding) }>{ children }</div>
    );
};


export default Page;
