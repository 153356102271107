import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faShare} from '@fortawesome/free-solid-svg-icons'; // pro: faPenCircle, faCirclePlus

import Alert from '../components/Alert';
import Page from '../components/Page';
// import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';

import UserEditForm from '../forms/UserEditForm';

import { AuthContext } from '../context/AuthContext';

import { classNames, Styles } from '../styling';
import api from '../api';

import { mockAlbumsMyself, mockAlbumsOther } from '../mock-data';


const Title = function({ user, className }) {
    if(!user) {
        return (null);
    }

    return (
        <h1 className={ classNames(Styles.h1_responsive, className, 'flex items-end gap-8') }>
            <div className="h-32 w-32 shrink-0">
                <img
                    alt={ user.fullName }
                    src={ user.thumbnail }
                    className="h-32 w-32 object-cover rounded-full"
                />
            </div>
            <div className="capitalize">
                <div className="mb-4 text-ellipsis overflow-hidden">{ user.fullName }</div>
                <div className="opacity-50 text-sm font-normal">
                    <Link to="/profile"><FontAwesomeIcon icon={ faPen } aria-hidden="true" className="pr-2" /> Edit</Link>
                </div>
            </div>
        </h1>
    );
};

const Shared = function({ user, className, notify, myself }) {

    const [feed, setFeed] = useState(null);

    const title = (myself) ? 'What I’m sharing with others' : 'What others have shared with me';
    const summary = (myself) ? 'You shared this album with' : 'Has shared their album with you';
    const icon = (myself) ? <FontAwesomeIcon icon={ faShare } /> : <FontAwesomeIcon icon={ faShare } className="fa-rotate-180" />;

    const mockData = (myself) ? mockAlbumsMyself : mockAlbumsOther;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        api.mock(mockData)
            .then(res => setFeed(res))
            .catch(error => notify('error', error));
    }, [user]);
    /* eslint-enable react-hooks/exhaustive-deps */

    if (!feed) {
        return (null);
    }

    const teaserHeight = 'h-24';      // 96px
    const teaserImgSz = 'w-32 h-24';       // 128px 96px
    const teaserImgSzRound = 'w-10 h-10';  // 40px;

    const font = 'text-sm md:text-base';
    const overflow = 'text-ellipsis overflow-auto';

    return (
        <aside className={ className }>
            <div className="flex flex-row">
                <h2 className={ classNames('basis-full lg:basis-3/5 mb-4 pb-4', 'border-b border-reminu--grey-3') }>{ icon } { title }</h2>
                <summary className="hidden lg:basis-2/5 lg:flex border-b border-reminu--grey-3 mb-4 text-reminu--grey-3">{ summary }</summary>
            </div>
            {
                feed.map((item, index) => (
                    <div key={ index } className="flex flex-col lg:flex-row items-stretch lg:items-center border-b border-b-reminu--grey-5">
                        <Teaser.Strict
                            image={ item.image }
                            className={ classNames(teaserHeight, 'my-2 mr-4 basis-full lg:basis-3/5', font) }
                            size={ teaserImgSz }
                            overflow={ overflow }
                        >
                            { item.description }
                        </Teaser.Strict>

                        <ul className={ classNames('basis-full lg:basis-2/5', font) }>
                            {
                                item.shared.map(
                                    (user, index) => (
                                        <li className="inline-block lg: block" key={ index }>
                                            <Teaser.Rounded
                                                image={ user.image }
                                                className="mr-2 lg:my-2 lg:mr-0"
                                                size={ teaserImgSzRound }
                                                overflow={ overflow }
                                                inner="px-4 hidden md:flex"
                                            >
                                                { `${user.first_name} ${user.last_name}` }
                                            </Teaser.Rounded>
                                        </li>
                                    )
                                )
                            }
                        </ul>
                    </div>
                ))

            }
        </aside>
    );
};

const Profile = function() {
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);

    const context = useContext(AuthContext);

    // always fetch fresh profile and update context
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        context.test(); // TODO
        // context.test()
        //     .then(() => setLoading(true))
        //     .catch(error => setError(error));
    }, []);
     /* eslint-enable react-hooks/exhaustive-deps */

    const user = context.getUser();
    const isLoading = () => !user;

    const notify = function(status, message) {
        setMessage(message);
        setStatus(status);
        return Promise.resolve(true);
    }

    return (
        <Page.Plain className="h-full">
            { /* <PageTitle route={ routes.profile } /> */ }

            <Preloader loading={ isLoading() } message="fetching contents" />
            <Alert.Any status={ status } message={ message } />

            <IfLoaded loading={ isLoading() }>
                <div className="h-full grid grid-cols-3 gap-4 content-stretch">

                    <section className={ classNames(Styles.pagePadding, 'col-span-3 md:col-span-1') }>
                        <Title user={ user } />
                        <UserEditForm
                            className="w-full md:max-w-sm"
                            user={ user }
                            notify={ notify }

                        />
                    </section>

                    <section className={ classNames(Styles.pagePadding, 'col-span-3 md:col-span-2', 'md:bg-reminu--grey-6') }>
                        <Shared user={ user } className="mb-8" myself />
                        <Shared user={ user } />
                    </section>

                </div>
            </IfLoaded>
        </Page.Plain>
    );
};


export default Profile;
