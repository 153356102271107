import { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';


const AppContext = createContext({
    dark: false,
    dev: false,
});

const AppProvider = function({ children }) {

    const location = useLocation();
    const [ dark, setDark ] = useState(false);
    const [ dev, setDev ] = useState(0);

    const onKeyDown = (e) => {
        // CTRL + / and CMD + /
        if (e.key === '/' && (e.ctrlKey || e.metaKey)) {
            setDev(!dev);
        }
    };

    useEffect(() => {
        // listen to location changes
        switch (location.pathname) {
            case '/':
            case'/home':
                setDark(true);
            break;
            default:
                setDark(false);
        }

        // listen to keyboard
        document.body.addEventListener('keydown', onKeyDown);

        // cleanup events on unmount
        return () => {
            document.body.removeEventListener('keydown', onKeyDown);
        }
    }, [location, onKeyDown]);

    return (
        <AppContext.Provider value={{
            dark,
            dev
        }}>{ children }</AppContext.Provider>
    );
};

export { AppContext, AppProvider };
