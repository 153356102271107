import React, { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";

import { AuthContext } from '../context/AuthContext';


const ProtectedRoute = ({ component: Component, path, exact, children }) => {
    const { loading, user } = useContext(AuthContext);

    if (loading) {
        return <div className="flex flex-col"><div className="flex-1"><strong>Authenticating...</strong></div></div>;
    }

    if (!user) {
        return (<Navigate to="/" />);
    }

    return children ? children : <Outlet />;
};


export default ProtectedRoute;
