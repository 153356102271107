import { useRef } from 'react';
import { classNames, Styles } from '../styling';


const bgForm = 'bg-reminu--grey-5';

const wrapperClass = [
    'mb-4',
    'bg-reminu--light text-reminu--dark rounded',
    'border-reminu--light',
    'hover:border-reminu--grey-0',
].join(' ');

const inputClass = [
    'bg-transparent',
    'transition duration-1000',
    'w-full p-2',
    'hover:bg-reminu--grey-6',
].join(' ');

const ParagraphForm = function({ className,paragraph, handleSubmit }) {
    const headlineRef = useRef();
    const contentRef = useRef();
    const pullquoteRef = useRef();

    const onSubmit = function(e) {
        e.preventDefault();

        paragraph = paragraph || {};
        paragraph.headline = headlineRef.current.value;
        paragraph.content = contentRef.current.value;
        paragraph.pullquote = pullquoteRef.current.value;

        handleSubmit(paragraph);
    };

    return (
        <form className={ classNames(className, bgForm, 'rounded') } onSubmit={ onSubmit }>
            <fieldset className={ wrapperClass }>
                <input
                    name="headline"
                    type="text"
                    ref= { headlineRef }
                    className={ classNames('mb-0', inputClass) }
                    defaultValue={ (paragraph) ? paragraph.headline : '' }
                    placeholder="Headline"
                />
                <textarea
                    name="content"
                    ref= { contentRef }
                    className={ inputClass }
                    defaultValue={ (paragraph) ? paragraph.content : '' }
                    placeholder="content"
                />
                <input
                    name="pullquote"
                    type="text"
                    ref= { pullquoteRef }
                    className={ inputClass }
                    defaultValue={ (paragraph) ? paragraph.pullquote : '' }
                    placeholder="pull quote"
                />
            </fieldset>

            <fieldset>
                <button type="submit" className={ Styles.btnSecondarySmall }>
                    Update
                </button>
            </fieldset>
        </form>
    );
};


export default ParagraphForm;
