/**
 * This module is just a (temp) scratch area for all kinds of shared layout data
 */

const Brand = {
    owner: {
        name: 'New Word Order',
        website: 'https://newwordorder.com.au',
    },
};

const Styles = {
    // colors

    darker:  'text-reminu--light dark:text-reminu--light bg-reminu--darker dark:bg-reminu--darker',

    dark: 'text-reminu--light dark:text-reminu--light bg-reminu--dark dark:bg-reminu--dark',
    light: 'text-reminu--dark dark:text-reminu--dark bg-reminu--light dark:bg-reminu--light',

    darkGrey: 'text-reminu--light dark:text-reminu--light bg-reminu--grey-0 dark:bg-reminu--grey-0',
    lightGrey: 'text-reminu--dark dark:text-reminu--dark bg-reminu--grey-4 dark:bg-reminu--grey-4',

    // scaffolding

    pagePadding:  'p-6',
    pagePaddingX: 'px-6',
    pagePaddingY: 'py-6',
    pagePaddingT: 'pt-6',
    pagePaddingB: 'pb-6',

    // footer

    footerPadding: 'px-6 py-6 md:py-2', // pagePaddingX + mobile y
    footerMobile: 'sm:text-reminu--light sm:bg-reminu--dark-0',

    // buttons

    btnPrimary: 'px-4 py-2 text-sm rounded border border-reminu--dark text-reminu--light bg-reminu--dark hover:bg-reminu--light hover:text-reminu--dark',
    btnPrimaryInversed: 'px-4 py-2 text-sm rounded border border-reminu--light text-reminu--dark bg-reminu--light hover:bg-reminu--dark hover:text-reminu--light',

    btnSecondary: 'px-4 py-2 text-sm rounded border border-reminu--dark bg-reminu--light text-reminu--dark hover:text-reminu--light hover:bg-reminu--dark',
    btnSecondarySmall: 'px-2 py-1 text-sm rounded border border-reminu--dark bg-reminu--light text-reminu--dark hover:text-reminu--light hover:bg-reminu--dark',

    btnRounded: 'px-4 py-2 text-sm outline-none border rounded-full border-reminu--dark hover:bg-reminu--grey-2',
    btnRoundedSmall: 'px-2 py-1 text-sm outline-none border rounded-full border-reminu--dark hover:bg-reminu--grey-2',

    btnRoundedPrimary: 'px-4 text-sm py-2 outline-none text-reminu--light bg-reminu--dark border rounded-full border-reminu--dark hover:bg-reminu--light hover:text-reminu--dark',
    btnRoundedLight: 'px-4 text-sm py-2 outline-none border rounded-full border-reminu--grey-3 hover:bg-reminu--grey-3',

    btnLight: 'p-4 text-sm outline-none border rounded text-reminu--grey-3 bg-reminu--light border-reminu--grey-3 hover:bg-reminu--grey-3 hover:bg-reminu--grey-3 hover:text-reminu--light',

    // forms

    textInput: 'py-2 px-4 bg-reminu--grey-5 appearance-none border border-reminu--grey-5 rounded leading-tight focus:outline-none focus:bg-white focus:border-status-active-dark',

    // typography

    h1: 'mb-4 text-4xl',
    h1_responsive: 'mb-4 sm:text-2xl md:text-3xl lg:text-4xl',
    h2: 'mb-2 mt-2 text-2xl', //  24px
    h2_responsive: 'mb-2 mt-2 sm:text-xl md:text-2xl',
    h2xl: 'mb-2 mt-2 text-3xl', // 30px
};

const classNames = function(...classes) {
    return classes.filter(Boolean).join(' ');
};

export { classNames, Styles, Brand };
