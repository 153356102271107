import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import { routes } from '../navigation';

import { AuthContext } from '../context/AuthContext';
import { Styles } from '../styling';


const Logout = function() {
    const context = useContext(AuthContext);
    const user = context.getUser();
    const navigate = useNavigate();

    const { login, logout } = routes;

    const handleLogout = (e) => {
        e.preventDefault();
        return context.logout()
            .then(() => navigate('/login'));
    };

    return (
        <Page.Simple>
            <PageTitle route={ logout } />

            <div className="flex flex-col gap-x-4 gap-y-4">
                <div className="mt-8 flex flex-col items-center justify-center">
                {
                    (user) ?
                        <>
                            <div className="mb-8">Are you sure you want to sign out?</div>
                            <div>
                                <button
                                    aria-current={ ({ active }) => active ? 'page' : null }
                                    className={ Styles.btnPrimary }
                                    title={ logout.name }
                                    onClick={ handleLogout }
                                >
                                    <FontAwesomeIcon icon={ logout.icon } aria-hidden="true" /> Log out
                                </button>
                            </div>
                        </>
                    :
                        <>
                            <div className="mb-8">You have been signed out</div>
                            <div>
                                <Link
                                    to={ login.path() }
                                    aria-current={ ({ active }) => active ? 'page' : null }
                                    className={ Styles.btnPrimary }
                                    title={ login.name }
                                >
                                    <FontAwesomeIcon icon={ login.icon } aria-hidden="true" /> Log in
                                </Link>
                            </div>
                        </>
                }
                </div>
            </div>
        </Page.Simple>
    );
};


export default Logout;
