/**
 * courtesy QoP, via https://stackoverflow.com/a/36862446
 */
import { useState, useEffect } from 'react';


const getWindowDimensions = function () {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height,
    };
}

const useWindowDimensions= function() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export default useWindowDimensions;
