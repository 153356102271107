import { useState } from 'react';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import { classNames, Styles } from '../styling';

const zBackDrop = 'z-10';
const zContent = 'z-20';


const ModalWindow = function ({ open, setOpen, title, actionCallback, actionName, children }) {
    return (
        <Dialog
            open={ open }
            onClose={ setOpen }
            className={ classNames(zBackDrop, 'relative') }
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className={ classNames(zContent, 'fixed inset-0 w-screen overflow-x-auto overflow-y-auto') }>
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className={
                            classNames(
                                'relative overflow-x-auto overflow-y-auto',
                                'rounded-lg bg-reminu--light text-left shadow-xl',
                                'transform transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in',
                                'data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
                                'sm:my-8 sm:mx-8',
                                'min-w-60 w-4/5'
                            )
                        }
                    >
                            <div className="bg-reminu--light px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex flex-col sm:items-start">
                                    { /* header */ }
                                    <div className="w-full my-2 sm:mt-0 sm:text-left">
                                        <div className="flex flex-row justify-between">
                                            <DialogTitle as="h4" className="text-base text-ellipsis grow pr-4 font-semibold leading-6 text-reminu--dark">
                                                { title}
                                            </DialogTitle>

                                            <FontAwesomeIcon
                                                onClick={ () => setOpen(false) }
                                                className="flex-none bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none hover:cursor-pointer hover:opacity-50" icon={ faCircleXmark }
                                            />
                                        </div>
                                    </div>
                                    { /* content */ }
                                    <div>{ children }</div>
                                </div>
                            </div>

                            <div className="bg-reminu--grey-5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                { /* footer */ }
                                <button
                                    type="button"
                                    onClick={ () => setOpen(false) }
                                    className={ classNames('mr-4', Styles.btnSecondary) }
                                >
                                    Cancel
                                </button>
                                {
                                    (typeof actionCallback === 'function') && (
                                        <button
                                            className={ Styles.btnPrimary }
                                            type="button"
                                            onClick={
                                                () => {
                                                    Promise.resolve(actionCallback())
                                                        .then(() => setOpen(false));
                                                }
                                            }
                                        > { actionName || 'Submit' }</button>
                                    )
                                }
                            </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

/**
 * Examples:
 *
 * <Modal actionCallback={ () => Promise.resolve(true) }>
 *     <p>
 *         Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
 *         Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Etiam porta sem malesuada magna mollis euismod.
 *         Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur.
 *     </p>
 * </Modal>
 *
 * <Modal
 *     btnText='View Content'
 *     actionCallback={ () => Promise.resolve(true) }
 * >
 *     <p>TODO</p>
 * </Modal>
 *
 * <Modal
 *     BtnComponent={ react.createElement('button') }
 *     actionCallback={ () => Promise.resolve(true) }
 * >
 *     <p>TODO</p>
 * </Modal>
 */

const Modal = function({ BtnComponent, btnText, btnClassName, ...rest }) {

    const [open, setOpen] = useState(false);

    return (
        <>
            {
                (BtnComponent) ?
                    <BtnComponent
                        className={ classNames('reminu--modal', btnClassName) }
                        onClick={ () => setOpen(true) }
                    />
                    :
                    <button
                        className={ classNames('reminu--modal', btnClassName) }
                        type="button"
                        onClick={ () => setOpen(true) }
                    >
                        { btnText || 'Open Modal' }
                    </button>
            }

            <ModalWindow
                open={ open }
                setOpen={ setOpen }
                // window
                { ...rest }
            />
        </>
    );
};


export { Modal as default, ModalWindow };
