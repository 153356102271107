const { REACT_APP_API_ENDPOINT } = process.env;
const API_ENDPOINT = REACT_APP_API_ENDPOINT.replace(/\/$/, '');

const _url = (path) => `${API_ENDPOINT}${path}`;
const _headers = (src, targ) => Object.assign(src, targ);
const _randInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

const UploadUri =_url('/upload_media'); // @see uppy xhr init

/**
 * set up AbortController
 * resolves: "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method."
 */
let controller = null;
let signal = null;

if (typeof window.AbortController !== 'undefined') {
    controller = new AbortController();
    signal = controller.signal;
}

class ApiError extends Error {
    constructor(message, response = {}) {
        super(message);

        this.name = 'ApiError';
        this.status = response.status || null;
        this.statusText = response.statusText || null;
        this.url = response.url || null;
    }

    toString() {
        return `[${this.status}:${this.statusText}] ${this.message} (${this.url})`;
    }
}

/**
 * Handles and formats response errors in a promise context
 * @param {Response} fetch response object @see https://developer.mozilla.org/en-US/docs/Web/API/Response
 * @returns {Promise} Promise object throwing an exception (being catched in flow)
 */
const responseError = function(response) {
    return response.text()
        .then((text) => {
            throw new ApiError(text.replace(/(<([^>]+)>)/ig, ''), response);
        });
};

const request = async function(path, data=null, headers={}) {
    const url = _url(path);

    const options =  {
        signal,
        credentials: 'include', // @see https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#credentials
        headers: _headers(headers || {}, {
            'Content-type': 'application/json; charset=UTF-8',
        }),
    };

    if (data) {
        options.method = 'POST';
        options.body =  JSON.stringify(data);
    }

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                return responseError(response);
            }
            return response.json();
        })
        .catch((error) => {
            if(error && error.name === 'AbortError') {
                return;
            }
            if(error.name === 'ApiError') {
                throw error;
            }
            throw new ApiError(error.toString(), { url });
        });
};

/**
 * @returns {Promise}
 */
const albums = async function() {
    return request('/api/albums');
    
};

/**
 * @returns {Promise}
 */
const profile = async function() {
    return request('/auth/api/profile')
    .then(res => res.user);
};

/**
 * @returns {Promise}
 */
const login = async function(data) {
    return request('/auth/api/login', data)
    .then(res => res.user);
};

/**
 * @returns {Promise}
 */
const logout = async function() {
    return request('/auth/api/logout')
    .then(res => res);
};

/**
 * @returns {Promise}
 */
const updateProfile = async function(data) {
    return request('/auth/api/profile', data);
};

/**
 * @returns {Promise}
 */
const mock = function(data) {
    const delay = _randInt(1000, 3000);
    let id;

    return new Promise((resolve) => {
        id = setTimeout(() => {
            resolve(data);
            clearTimeout(id);
        }, delay);
    })
};

const Api = {
    albums,
    profile,

    login,
    logout,

    updateProfile,
    mock,

    UploadUri,
};


export { Api as default, UploadUri };
