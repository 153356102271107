import { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'; // pro: faCirclePlus

import MediaUpload from './MediaUpload';
import { classNames, Styles } from '../styling';


const initUploads = () => [null, null];
const allowedMedia = ['image'];


/**
 * Album page form
 */
const PageForm = function({ page, handleSubmit, className }) {

    const taHeight = 'h-28'; // 112px

    const titleRef = useRef();
    const subtitleRef = useRef();
    const uploads = initUploads();

    const onSubmit = function(e) {
        e.preventDefault();

        page = page || {};
        page.title = titleRef.current.value;
        page.subtitle = subtitleRef.current.value;

        handleSubmit(page);
    };

    return(
        <form className="w-full" onSubmit={ onSubmit }>

            <fieldset className="mb-4">
                <label htmlFor="title">Headline</label>

                <textarea
                    name="title"
                    className={ classNames('mt-2 w-full', taHeight, Styles.textInput) }
                    defaultValue={ (page) ? page.title : '' }
                    ref={ titleRef }
                    placeholder="A headline that describes this memory..."
                />
            </fieldset>

            <fieldset className="mb-4">
                <label htmlFor="subtitle">Description</label>

                <textarea
                    name="subtitle"
                    className={ classNames('mt-2 w-full', taHeight, Styles.textInput) }
                    defaultValue={ (page) ? page.subtitle : '' }
                    ref={ subtitleRef }
                    placeholder="In brief terms describe who is involved and where..."
                />
            </fieldset>

            {
                // TODO: Layouts: can this be removed in favour of a single upload (below)
                uploads.map((upload, index) => (
                    <fieldset className="mb-4" key={ index }>
                        <div className="w-full">Insert media...</div>
                        <MediaUpload
                            appearance="simple"
                            media={ upload }
                            allowedMedia={ allowedMedia }
                            className={ classNames('w-full h-28 text-5xl', Styles.btnLight) }
                        />
                    </fieldset>
                ))
            }

            <fieldset className="mb-4">
                <MediaUpload
                    appearance="decorated"
                    media={ null }
                    allowedMedia={ allowedMedia }
                    className={ classNames(Styles.btnLight, 'w-full h-16 p-8 text-xl') }
                />
            </fieldset>

            <fieldset className="mb-4">
                <button
                    type="submit"
                    className={ classNames('font-bold', Styles.btnRounded) }
                >
                    <FontAwesomeIcon icon={ faCircleRight } /> Save
                </button>
            </fieldset>

        </form>
    );
};


export default PageForm;
