import { faHouse, faBook, faMap, faCircleExclamation, faMagnifyingGlass, faGear, faRightToBracket, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const routes = {

    // primary

    home: {
        name: 'Home',
        path: () => '/home',
        route: 'home',
        icon: faHouse,
    },
    albums: {
        name: 'Albums',
        path: () => '/albums',
        route: 'albums',
        icon: faBook,  // pro: fa-books
    },
    guidedPlans: {
        name: 'Guided plans',
        path: () => '/guided-plans',
        route: 'guided-plans',
        icon: faMap,  // pro: fa-map-location
    },
    notifications: {
        // curr same as /profile
        name: 'Notifications',
        path: () => '/notifications',
        route: 'notifications',
        icon: faCircleExclamation,
    },
    profile: {
        name: 'Profile',
        path: () => '/profile',
        route: 'profile',
        icon: faCircleExclamation,
    },

    // secondary

    search: {
        name: 'Search',
        path: () => '/search',
        route: 'search',
        icon: faMagnifyingGlass,
    },
    settings: {
        name: 'Settings',
        path: () => '/settings',
        route: 'settings',
        icon: faGear,
    },
    logout: {
        name: 'Logout',
        path: () => '/logout',
        route: 'logout',
        icon: faRightFromBracket,
    },

    // other

    login: {
        name: 'Login',
        path: () => '/login',
        route: 'login',
        icon: faRightFromBracket, // jb added (not in layout)
    },
    notfound: {
        name: 'Page not found',
        path: () => '/not-found',
        route: 'not-found',
        icon: faRightToBracket, // jb added (not in layout)
    },
    todo: {
        name: 'To Do',
        path: () => '/todo',
        route: 'todo',
        icon: null,
    },
    brand: {
        name: 'Reminu brand',
        path: () => '/brand',
        route: 'brand',
        icon: null,
    },

    // album sub pages

    albumPages: {
        // edit single album
        name: 'Album',
        path: (album_id) => `/albums/${album_id}`,
        route: 'albums/:album_id',
        icon: null,
    },

    albumEdit: {
        // edit single album
        name: 'Edit Album',
        path: (album_id='new') => `/albums/${album_id}`,
        route: 'albums/:album_id/edit',
        icon: null,
    },

    albumChapter: {
        // view single album chapter, TODO clarify if required and  and finish AlbumChaptersLayout
        name: 'View Album Chapter',
        path: (album_id, chapter_id) =>  `/albums/${album_id}/chapters/${chapter_id}`,
        route: 'albums/:album_id/chapters/:chapter_id',
        icon: null,
    },
    albumChapterEdit: {
        // edit single album chapter
        name: 'Edit Album Chapter',
        path: (album_id, chapter_id='new') =>  `/albums/${album_id}/chapters/${chapter_id}/edit`,
        route: 'albums/:album_id/chapters/:chapter_id/edit',
        icon: null,
    },

    albumPage: {
        // view single album page (magazine layout)
        name: 'View Album Page',
        path: (album_id, page_id) => `/albums/${album_id}/pages/${page_id}`,
        route: 'albums/:album_id/pages/:page_id',
        icon: null,
    },
    albumPageEdit: {
        // edit single album page
        name: 'Edit Album Page',
        path: (album_id, page_id='new') => `/albums/${album_id}/pages/${page_id}/edit`,
        route: 'albums/:album_id/pages/:page_id/edit',
        icon: null,
    },

    // guided plan

    guidedPlanEdit: {
        // start single guided plan
        name: 'Guided Plan',
        path: (plan_id) => `/guided-plans/${plan_id}`,
        route: 'guided-plans/:plan_id',
        icon: null,
    },

    // notification

    notification: {
        // view a single notification
        name: 'Notification',
        path: (notification_id) => `/notifications/${notification_id}`,
        route: 'notifications/:notification_id',
        icon: faMap,  // pro: fa-map-location
    },
};

/**
 * Get a routes object by either it's key or it's route pattern
 */
const get_route = function(match) {
    for (const key in routes) {
        if (key === match) {
            return routes[key];
        }
        if (routes[key].route === match) {
            return routes[key];
        }
    }
    return null;
};

/**
 * Ensure that a Router.path pattern is registered
 * This function will be only used in App.js when setting up the routes.
 * It keeps the path there readable, whilst ensuring that they match with an entry here
 */
const ensure_route = function(match) {
    for (const key in routes) {
        if (routes[key].route === match) {
            return match;
        }
    }
    throw new Error(`No such route definied: "${match}"`);
};

export { routes, ensure_route, get_route };
