import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { classNames } from '../styling';


const Notification = function({ status, message, title, className }) {

    const [closed, setClosed] = useState(false);

    if(closed || !message) {
        return(null);
    }

    let colors = '';

    // no dynamic classnames in tailwind.css
    switch (status) {
        case 'error':
            colors = 'text-status-error-dark bg-status-error-light border-status-error-dark';
            break;
        case 'success':
            colors = 'text-status-success-dark bg-status-success-light border-status-success-dark';
        break;
        case 'info':
            colors = 'text-status-success-dark bg-status-info-light border-status-info-dark';
        break;
        default:
            colors = 'text-status-none-dark bg-status-none-light border-status-none-dark';
    }

    return (
        <div className={ classNames(className, 'border-l-8 p-4 flex content-center', colors) } role="alert">
            <div className="grow"><strong className="capitalize mr-4">{ title || status }:</strong>{ message.toString() }</div>
            <div className="flex-none">
                <button className="ml-4 opacity-60 hover:opacity-100 " onClick={ () => setClosed(true) }>
                    <FontAwesomeIcon icon={ faXmark } />
                </button>
            </div>
        </div>
    );
};

const Alert = {};

Alert.Any = function(props) {
    return (
        <Notification { ...props } />
    );
};

Alert.Error = function(props) {
    return (
        <Notification status="error" { ...props } />
    );
};

Alert.Info = function(props) {
    return (
        <Notification status="info" { ...props } />
    );
};


export default Alert;
