import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons';

import MediaUpload from './MediaUpload';

import Alert from '../components/Alert';
import Preloader from '../components/Preloader';

import { classNames, Styles } from '../styling';
import { timeout } from '../utils';

import api from '../api';
import { getMedium } from '../media';


const initUploads = () => [null, null, null, null];


const getAllowedMediaFromRecord = function(record) {
    const list = [];

    Object.keys(record).forEach((key) => {
        const medium = getMedium(key);
        if (medium && record[key]) { // ensure field value is truthy, otherwise skip
            list.push(medium);
        }
    });

    return list;
};

/**
 * guided plan prompt form
 */
const PromptForm = function({ guidedPlan, prompt, className }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);

    const [answer, setAnswer] = useState('');
    const uploads = initUploads(); // TODO

    const notify = function(status, message, millisec=0) {
        setMessage(message);
        setStatus(status);

        if (millisec > 0) {
            return timeout(() => {
                setMessage(null);
                setStatus(null);
            }, millisec);
        }

        return Promise.resolve(true);
    };

    const finalize = function(status, message, millisec=0) {
        setLoading(false);
        notify(status, message, millisec);
    };

    const onSubmit = () => {
        setLoading(true);
        api.mock('TODO')
            .then(() => finalize('info', 'content updated'))
            .catch(error => finalize('error', error));
    };

    if (!guidedPlan || !prompt) {
        return (null);
    }

    const width = 'w-full md:w-5/6'; // 83.333333%
    const taHeight = 'h-96';  // 384px
    const uDim =  'w-full min-w-32 h-20 min-h-20'; // 128px x 80px

    const allowedMedia = getAllowedMediaFromRecord(prompt);
    const allowedListed = allowedMedia.map(medium => medium.name).join(', ');

    return(
        <form className="w-full" onSubmit={ onSubmit }>
            <Preloader loading={ loading } message="updating content" />
            <Alert.Any status={ status } message={ message } />

            <h2 className={ Styles.h2 }>{ prompt.prompt }</h2>

            <div className={ width }>
                <fieldset className="mb-4">
                    <textarea
                        className={ classNames('mt-2 w-full', taHeight, Styles.textInput) }
                        value={ answer }
                        onChange={ e => setAnswer(e.target.value) }
                        placeholder="type here..."
                    />
                </fieldset>

                <fieldset className="mb-4">
                    <p>{ `Do you have any ${allowedListed}, you want to share?` }</p>
                </fieldset>

                <fieldset className="flex gap-4 justify-between align-center mb-8">
                    {
                        uploads.map((upload, index) => (
                            <div key={ index }>
                                <MediaUpload
                                    media={ upload }
                                    allowedMedia={ allowedMedia }
                                    className={ classNames(uDim, Styles.btnLight) }
                                    appearance="simple"
                                />
                            </div>
                        ))
                    }
                </fieldset>

                <fieldset>
                    <button
                        type="submit"
                        className={ classNames('font-bold', Styles.btnRounded) }
                        onClick={ (e) => {
                            e.preventDefault();
                            //TODO
                        } }
                    >
                        <FontAwesomeIcon icon={ faCircleRight } /> Save and next
                    </button>
                </fieldset>
            </div>
        </form>
    );
};


export default PromptForm;
