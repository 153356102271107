import { useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../context/AppContext';
import { classNames } from '../styling';



const Dev = function DevResponsive({ className, top=false, right=false, children }) {
    const context = useContext(AppContext);

    if (!context.dev) {
        return (null);
    }

    const cy = (top) ? 'top-6' : 'bottom-6';
    const cx = (right) ? 'right-2' : 'left-2';

    const width = 'w-auto';

    return (
        <section className="relative w-full z-50 text-xs">
            <div className={ classNames('fixed', cy, cx , width, 'rounded', className) }>
                <div className="flex gap-4">
                    { children }
                </div>
            </div>
        </section>
    );
};

/**
 * @see https://tailwindcss.com/docs/responsive-design
 * sm   640px   @media (min-width: 640px) { ... }
 * md   768px   @media (min-width: 768px) { ... }
 * lg   1024px  @media (min-width: 1024px) { ... }
 * xl   1280px  @media (min-width: 1280px) { ... }
 * 2xl  1536px  @media (min-width: 1536px) { ... }
 *
 * @see extensuons in ../../tailwind.config.js
 */
Dev.Responsive = function DevResponsive({ className }) {

    const prefixes = {
        'sm':        'bg-gray-200 sm:bg-blue-400 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200', // 640px  @media (min-width: 640px) { ... }
        'md':        'bg-gray-200 sm:bg-gray-200 md:bg-blue-400 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200', // 768px  @media (min-width: 768px) { ... }
        'lg':        'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-blue-400 xl:bg-gray-200 2xl:bg-gray-200', // 1024px @media (min-width: 1024px) { ... }
        'xl':        'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-blue-400 2xl:bg-gray-200', // 1280px @media (min-width: 1280px) { ... }
        '2xl':       'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-blue-400', // 1536px @media (min-width: 1536px) { ... }

        'lgr':       'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200 lgr:bg-amber-700',
    };

    return (
        <div className={ classNames(className) }>
            {
                Object.keys(prefixes).map((key, index) => (
                    <span key={ index } className={ classNames('px-1 py-0 mr-1', prefixes[key]) }>{ key }</span>
                ))
            }
        </div>
    );
};

/**
 * @see https://tailwindcss.com/docs/responsive-design
 * sm   640px   @media (min-width: 640px) { ... }
 * md   768px   @media (min-width: 768px) { ... }
 * lg   1024px  @media (min-width: 1024px) { ... }
 * xl   1280px  @media (min-width: 1280px) { ... }
 * 2xl  1536px  @media (min-width: 1536px) { ... }
 *
 * @see extensions in ../../tailwind.config.js
 */
Dev.NavigateTo = function DevNavigateTo({ className }) {

    const navigate = useNavigate();
    const location = useLocation();

    const routeRef = useRef(location.pathname);

    const handleSubmit = (event) => {
        event.preventDefault();

        const route = routeRef.current.value;
        if(route) {
            navigate(route);
        }
    };

    return (
        <form
            className={ classNames(className) }
            onSubmit={ handleSubmit }
        >
            <input type="text"  className="opacity-85 px-2 bg-reminu--light" placeholder="route..." ref={ routeRef } />
            <button type="submit" className="opacity-85 px-2 ml-2 bg-reminu--light">Browse</button>
        </form>
    );
};


export default Dev;
