import { classNames } from '../styling';

/*
 * @param items: an array of objects, having at least an 'id' property
 * [{ id, (name), ... },{ id, (name), ... }, ...]
 * @param getItemName: getter function for displaying the option value, use this in case items have no proprty 'name'
 */

const Select = function({ className, items=[], activeItem, getItemName, setActiveItem, label='', description='' }) {

    if (!items || !items.length) {
        return (null);
    }

    return (
        <fieldset>
            { label && <label className="text-sm">{ label }</label> }
            <select
                className={ classNames(className, 'w-full rounded-md px-4 py-2 text-base text-ellipsis') }
                defaultValue={ (activeItem) ?  activeItem.id : null }
                onChange={
                    (e) => {
                        const id = parseInt(e.target.value, 10);
                        const matches = items.filter(item => item.id === id);
                        if (matches.length) {
                            setActiveItem(matches[0]);
                        }
                    }
                }
            >
                {
                    items.map(
                        (item) => (
                            <option
                                key={ item.id }
                                value={ item.id }
                            >
                                { (typeof getItemName === 'function') ? getItemName(item) : item.name }
                            </option>
                        )
                    )
                }
            </select>
        </fieldset>
    );

};

export default Select;
